import React from "react";
import { connect } from "react-redux";
import { stringLimit } from "../../utils/helpers";
import Link from 'next/link';
import { redirect } from "../../utils/redirect";
const GridBoxComponent = ({ title, image, subTitle, info, href, as }) => {
    return (<Link href={href} as={as}>
        <div style={{ cursor: 'pointer' }} onClick={(e) => {
            e.preventDefault();
            redirect(href, {}, as, true);
        }} className="job-grid-view pb-0">
            <div className="d-flex justify-content-between">
                <h2 title={title}>
                    <Link href={href} as={as}>
                        <a
                            style={{ color: '#555', fontSize: 17 }}
                            href={as}
                        >
                            {stringLimit(title, 30)}
                        </a>
                    </Link>
                </h2>
            </div>

            <div className="company_info">
                <div className="c_logo">
                    <img src={image} alt="" />
                </div>
                <div className="info">
                    <h5 title={subTitle}>{stringLimit(subTitle, 30)}</h5>
                    <a href={(e) => {
                        e.preventDefault();
                    }}>{info}</a>
                    {/* {item.company.domain ?
                    <a href={'http://www.' + item.company.domain.replace(new RegExp('\\.*www.', 'gi'), '').replace(/^http?.:\/\//, '')}
                        target='_blank'>
                        <i className="fas fa-external-link-alt" /> {item.company.domain.toLowerCase().replace(new RegExp('\\.*www.', 'gi'), '')}
                    </a> : null} */}
                </div>
            </div>

            <br />

            {/* {!isSlim ? <div className="row">
            <div title={item.job_location} className="col-6">
                <h4>Location</h4>
                <p style={{ marginTop: 0, fontSize: 12 }}>{stringLimit(item.job_location, 16)}</p>
            </div>
            <div title={item.education} className="col-6">
                <h4>Education</h4>
                <p style={{ marginTop: 0, fontSize: 12 }}>{stringLimit(item.education, 16) || '-'}</p>
            </div>
        </div> : null} */}


            {/* {!isSlim ? <div className="grid-footer">
            <div className={'expiry-days'}>
                <span
                    className={'expiry ' + remainDayClass}>{ucFirst(item.expired_at.human)}</span>
            </div>
            {cardType === 'MY_FAVOURITE_LIST' ? <div className={'user-action'}>
                <Button onClick={() => {
                    redirect('/job/detail/[slug]', {}, `/job/detail/${item.slug}`);

                    // jobGeneralState({props: 'selectedApplicant', value: item});
                    // userDetails(item.id, {}, true);
                }}>Open</Button> &nbsp;&nbsp;
                    <Button type="danger" onClick={() => {
                    confirm({
                        title: 'Remove from favourite list?',
                        content: 'Click the Yes button if you want to remove this job from your favourite list',
                        okText: 'Yes',
                        cancelText: 'No',
                        onOk() {
                            removeFavourite(item.favourite_id, 'job')
                        }
                    });
                }}><Icon type="delete" />
                        Remove</Button>
            </div> : <div className={'user-action'}>
                    <a href="#"><i className="far fa-heart" /></a>
                    <Link href={`/job/detail/[slug]`} as={`/job/detail/${item.slug}`}>
                        <a href={`/job/detail/${item.slug}`}>
                            <i className="far fa-eye" />
                        </a>
                    </Link>
                </div>}
        </div> : null} */}
        </div>
    </Link>
    );
};


const mapStateToProps = (state) => {
    return {
    }
};

const GridBox = connect(mapStateToProps, {

})(GridBoxComponent);

export { GridBox };