import {Button, Icon, Input} from "antd";
import React, {useState, useRef} from "react";
import {ucFirst} from "../../utils/helpers";
import {AppModal} from "./AppModal";

const {Search} = Input;

const EmailSetting = ({user, auth, verificationLink, settingInputChange, updateLoginId, verifyEmail}) => {
    let [value, setValue] = useState('');
    let [loader, setLoader] = useState(false);

    const handleAddValue = (key) => {
        setValue(key);
    };

    return <div className="setting-email-address">
        <div className="card-container">
            <div className="card-header">
                <h4>Email</h4>
            </div>
            {!user.settings.email.edit && !auth.verificationLinkSent && !auth.retryInterval ?
                <div className="card-body">
                    <div className="icon-avatar">
                        <Icon type="mail"/>
                    </div>
                    <div className="body-content">
                        <p>
                            <strong>{user.settings.email.value}
                                {user.data.ev_at ? <sup>
                                    &nbsp;<Icon type="check-circle" theme="twoTone"
                                                twoToneColor="#52c41a"/>
                                </sup> : <sup>&nbsp;<Icon type="close-circle" theme="twoTone"
                                                          twoToneColor="#dc3545"/></sup>}
                            </strong>
                        </p>
                        {user.data.ev_at ?
                            <small>{user.data.ev_at.str} | {ucFirst(user.data.ev_at.human)}.</small> :
                            <small style={{color: '#dc3545'}}>Email has not been
                                verified.</small>}

                    </div>
                    <div className="body-action">
                        {user.data.ev_at ? null :
                            <Button size={'small'} loading={auth.verifyLoader} disabled={false}
                                    onClick={() => {
                                        verificationLink(user.settings.email.value)
                                    }}
                                    type="default">
                                Verify
                            </Button>} &nbsp;
                        <Button size={'small'} loading={false} disabled={false}
                                onClick={() => {
                                    settingInputChange({
                                        props: 'edit',
                                        value: true,
                                        type: 'email'
                                    });
                                }}
                                type="default">
                            Edit
                        </Button>
                    </div>
                </div> : auth.verificationLinkSent || auth.retryInterval > 0 ?
                    <div className={'card-body verification_link'}>
                        <div className={'container'}>
                            <div className="row">
                                <div className="col-sm-12"><h2>Verification Link was Sent</h2>
                                    <br/>
                                    <p>Please check your inbox
                                        @ <strong>{user.settings.email.value}</strong></p>
                                    <p>or you can {auth.retryInterval > 0 ?
                                        ' try again in ' + auth.retryInterval + ' seconds' :
                                        auth.verifyLoader ? <Icon type="loading"/> :
                                            <a href="" onClick={(e) => {
                                                e.preventDefault();
                                                verificationLink(user.settings.email.value);
                                            }}>try again
                                            </a>}</p>
                                </div>
                            </div>
                        </div>
                    </div> :
                    <div className={'card-body'}>
                        <div className={'container'}>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label>Email<span className="pull-right">:</span>
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="form-group">
                                        <Input type={'email'} value={user.settings.email.updateCopy}
                                               placeholder=""
                                               onChange={(e) => {
                                                   settingInputChange({
                                                       props: 'updateCopy',
                                                       value: e.target.value,
                                                       type: 'email'
                                                   });
                                               }}/></div>
                                </div>
                            </div>

                            <hr/>

                            <br/>


                            <Button
                                onClick={() => {
                                    settingInputChange({
                                        props: 'edit',
                                        value: false,
                                        type: 'email'
                                    });
                                }}
                                type="default">
                                Cancel
                            </Button>
                            &nbsp;&nbsp;&nbsp;

                            <Button loading={false}
                                    disabled={!user.settings.email.updateCopy.length || user.settings.email.updateCopy === user.settings.email.emailCopy}
                                    onClick={() => {
                                        updateLoginId(user.settings.email.updateCopy);
                                    }}
                                    type="primary">
                                Update Email
                            </Button>
                        </div>
                    </div>}


            <AppModal show={user.settings.email.updateWasSuccess} onClose={() => {
            }} noClose={true}>
                <div className="update-email">
                    <h2>You requested to update your email address.</h2>
                    <br/>
                    <p>A reset code has been sent to your new email address <strong
                        style={{fontWeight: 'bold'}}>&lt;{user.data.unverified_email}&gt;</strong>. Please input the
                        reset code to replace the old email. {value}</p>
                    <br/>
                    <Search
                        placeholder="Input reset code"
                        enterButton="Reset"
                        size="large"
                        defaultValue={value}
                        maxLength={5}
                        onChange={(e) => {
                            handleAddValue(e.target.value);
                        }}
                        loading={loader}
                        onSearch={value => {
                            if (value.length === 5) {
                                setLoader(true);
                                verifyEmail(value, {query: {resetEmail: user.data.unverified_email}});
                                setTimeout(() => {
                                    setLoader(false);
                                }, 700);
                            }
                        }}
                    />
                    <br/>
                    <br/>
                    <br/>

                    {auth.retryInterval > 0 ?
                        <p style={{color: 'red'}}>Please retry in {auth.retryInterval} seconds</p> :
                        <p>Didn't receive reset code? You can
                            <a href="#" style={{fontWeight: 'bold'}} onClick={(e) => {
                                e.preventDefault();
                                verificationLink(user.data.unverified_email, true);
                            }}> {auth.verifyLoader ?
                                <Icon type="loading"/> : ' Try again.'}</a>
                        </p>}
                </div>
            </AppModal>

        </div>
    </div>;
};

export {EmailSetting};
