import { ucWords } from "../../utils/helpers";

const SlimInfoCard = ({ onCardClick, cardImage, title = '', subTitle = '', shortInfo = '' }) => {
    return (<div style={{ cursor: onCardClick ? 'pointer' : 'normal' }} onClick={() => {
        if (onCardClick)
            onCardClick();
    }}>
        <div className="job-grid-view user-profile-grid">
            <div className="about-user">
                <div className="user-img-grid">
                    <div className="img-holder">
                        <img src={cardImage} loading={'lazy'} alt={ucWords(title)} onError={(e) => {
                            let userDefaultImage = 'https://rojal.s3.ap-south-1.amazonaws.com/defaults/profile_male.png';
                            e.target.src = userDefaultImage;
                        }} />
                    </div>
                </div>
                <div className="user-info d-flex justify-content-between">
                    <div>
                        <h2 className={'user-title'}>{ucWords(title) || '-'}</h2>
                        <div className={'expertise_field'}>
                            {subTitle}
                        </div>
                        <p style={{ marginTop: 10 }}>{shortInfo}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

export { SlimInfoCard };