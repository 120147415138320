import React from 'react';
import PropTypes from 'prop-types';
import {Icon} from "antd";

const VError = (props) => {

    const {eFor, errors} = props;
    let vError = null;

    for (let i = 0; i < errors.length; i++) {
        if (errors[i].context.key === eFor) {
            return (<span className={'v-error'}>
                <Icon type="exclamation-circle" /> {errors[i].message}
            </span>)
        }
    }
    return null;
};

VError.propTypes = {
    eFor: PropTypes.string.isRequired,
    errors: PropTypes.array.isRequired
};

export {VError};