import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { Modal } from 'antd';

import { availabilityType } from "../../utils/helpers";
import { NotFound } from "./NotFound";
import { Icon } from "antd";
import { connect } from 'react-redux';
import { publishJobPost, unpublishJobPost } from './../../store/actions'
import { LogoGenerator } from '.';
const { confirm } = Modal;

const JobListComp = ({ data, applyJob, myPost, deletePost, isApplied, ica, selectRenewJob, isTemporary, publishJobPost, unpublishJobPost }) => {
    if (!data.length) return <NotFound />;
    const color = ['#F6941C', '#10B04A'];

    return data.map(function (item, i) {
        return (<div key={i} className={"list_view " + (item.status !== 1 && !isTemporary ? 'alert_view' : '')}>
            {!myPost ? <div className="job_logo">
                {item.company.logo !== 'https://rojal.s3.ap-south-1.amazonaws.com/rojal/logo/logo_placeholder.jpg' ?  <img
                    src={item.company ? item.company.logo : ''}
                    alt={item.company ? item.company.name : ''} /> : <LogoGenerator height={132} name={item.company.name}/>}
            </div> : null}
            <div className="job_details">
                <h2>
                    {isTemporary && myPost ?
                        <a href={`/job/detail-mock?mock_post_id=${item.id}&_rg_cd=${item.job_code}`} target="_blank">
                            {item.title}
                        </a>
                        : <Link href={`/job/detail/[slug]`} as={`/job/detail/${item.slug}`}>
                            <a href={`/job/detail/${item.slug}`}>{item.title}</a>
                        </Link>}
                </h2>

                {!myPost ? <p><i className="fas fa-warehouse" /> {item.company ? item.company.name : ''}</p> : null}
                <p><span><i className="fas fa-map-marker-alt" /> {item.job_location}</span> &nbsp; &nbsp;
                    {!item.is_expired ? <span> <i className="far fa-clock" />
                        {isTemporary ? 'Created ' : 'Published '}
                        {item.created_at.human}, &nbsp; Expires {item.expired_at.human || item.expired_at.iso}
                    </span> :
                        <span style={{ color: '#dc3545' }}> <i style={{ color: '#dc3545' }} className="far fa-clock" />
                            {isTemporary ? 'Created ' : 'Published '}
                            {item.created_at.human}, &nbsp; Expired {item.expired_at.human}
                        </span>}
                </p>
                {item.applied_at ? <p style={{
                    color: '#777',
                    fontWeight: 'bold'
                }}>Applied {item.applied_at.human}, {item.applied_at.format}</p> : null}

                {myPost ? <hr /> : null}
                {myPost ? <p className={'action-text'} style={{ marginBottom: 0 }}>
                    <Link href={isTemporary ? `/company/post-job?temp_post_id=${item.id}` : `/company/post-job?update=${item.id}`}>
                        <a href={isTemporary ? `/company/post-job?temp_post_id=${item.id}` : `/company/post-job?update=${item.id}`}>
                            <Icon type="setting" />
                            <span>Update</span>
                        </a>
                    </Link> &nbsp;&nbsp;&nbsp;

                    {item.is_expired && !isTemporary ? <span><a style={{ color: '#00b365' }} href={'#'} onClick={(e) => {
                        e.preventDefault();
                        selectRenewJob(item.id);
                    }}>
                        <Icon style={{ color: '#00b365' }} type="setting" />
                        <span>Renew</span>
                    </a>
                        &nbsp;&nbsp;&nbsp;</span> : null}

                    {!item.is_expired && !isTemporary ? <span><a style={{ color: item.status === 1 ? '#F7931D' : '#00b365' }} href={'#'} onClick={(e) => {
                        e.preventDefault();
                        let message = 'Unpublishing a job will unlist your job from the live website , hence employers won\'t be able to see / apply etc. Click yes if you still want to continue.';
                        if (item.status === 0) {
                            message = 'Post will reappear in the live website enabling employers to see and apply on this job post.'
                        }
                        confirm({
                            title: 'Are you sure?',
                            content: message,
                            onOk() {
                                unpublishJobPost(item.id, item.status);
                            },
                            onCancel() {
                            },
                        });
                    }}>

                        <Icon style={{ color: item.status === 1 ? '#F7931D' : '#00b365' }} type="stop" />
                        {item.status === 1 ? <span>Unpublish</span> : <span>Publish</span>}
                    </a>
                        &nbsp;&nbsp;&nbsp;</span> : null}



                    <a href="" onClick={(e) => {
                        e.preventDefault();
                        confirm({
                            title: 'Are you sure?',
                            content: 'Deleting your job post won\'t be recoverable in the future. All your data related to the post will be lost',
                            onOk() {
                                deletePost(item.id, isTemporary);
                            },
                            onCancel() {
                            },
                        });
                    }} className={'error'}><Icon type="delete" />Delete</a>
                </p> : null}
            </div>
            <div className="job_action">

                {item.availability ? <span className={'a_' + item.availability}>
                    {availabilityType(item.availability)}</span> : null}
                {!isApplied ? <div>
                    {!myPost && ica !== true ? <a href="" onClick={(e) => {
                        e.preventDefault();
                        applyJob(item.title, item.id);
                    }} className={'btn btn-default ' + (item.is_applied_public === true ? ' disabled' : '')}>
                        {item.is_applied_public ? <strong><i className={'fa fa-check-circle'} />Applied</strong> : 'Apply'}
                    </a> :
                        myPost && item.deadline_latest ? <Link href={`/company/applicant/[slug]?expiry=${item.deadline_latest.id}`}
                            as={`/company/applicant/${item.slug}?expiry=${item.deadline_latest.id}`}>
                            <a className={'btn btn-default'} href="">
                                {item.applicants_count_single} Applicants
                            </a>
                        </Link> : (isTemporary && myPost && !item.is_expired ? <a onClick={(e) => {
                            e.preventDefault();
                            if (publishJobPost) {
                                publishJobPost(item);
                            }
                        }} className={'btn btn-default'} href="">
                            Publish Now
                        </a> : null)}
                </div> : null}
            </div>

        </div>);
    });
};

const mapStateToProps = (state) => {
    return {
    }
};
JobListComp.propTypes = {
    data: PropTypes.array.isRequired,
    myPost: PropTypes.bool,
    deletePost: PropTypes.func
};
const JobList = connect(mapStateToProps, {
    publishJobPost,
    unpublishJobPost
})(JobListComp);


export { JobList };