import React from 'react'
import { Modal, Tooltip, Icon } from 'antd';
const ChooseFromTwoModal = ({ showModal = false, title, subTitle, optionFirst = {}, optionSecond = {}, onClose }) => {
    return (
        <div className="my_modal">
            <Modal
                visible={showModal}
                className={'account_type_modal renewal_date'}
                onOk={() => {
                }}
                onCancel={() => {
                    if (onClose) {
                        onClose();
                    }
                }}
                footer={[]}>
                <br />


                <div className="acc_label">
                    <p>{title}</p>
                    {subTitle ? <p>{subTitle} </p> : null}
                </div>

                {<div className="acc_selector">
                    <Tooltip placement="topLeft"
                        title={optionFirst.tooltipText}>
                        <div onClick={() => {
                            if (optionFirst.onClick) {
                                optionFirst.onClick();
                            }
                        }} className="personal select_box">
                            <Icon type={optionFirst.icon} />
                            <p>{optionFirst.title}</p>
                            <small>{optionFirst.info}</small>
                        </div>
                    </Tooltip>
                    <Tooltip placement="topLeft"
                        title={optionSecond.tooltipText}>
                        <div onClick={() => {
                            if (optionSecond.onClick) {
                                optionSecond.onClick();
                            }
                        }} className="company select_box">
                            <Icon type={optionSecond.icon} />
                            <p>{optionSecond.title}</p>
                            <small>{optionSecond.info}</small>
                        </div>
                    </Tooltip>
                </div>
                }

                <br />
                <br />

                <p className={'help_text'}><Icon type="question-circle" /> Need help? <Tooltip placement="topLeft"
                    title="Checkout our tutorial on 'how to renew your job post?'"><u><a
                        href="">Click here</a></u></Tooltip></p>
            </Modal>
        </div>
    );
}

export { ChooseFromTwoModal }