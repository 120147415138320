import React from 'react';
import PropTypes from 'prop-types';
import {Icon, Select} from "antd";
const Option = Select.Option;

const SelectCategory = (props) => {

    const {selected, onSelect, data, placeholder} = props;

    return <Select
        style={{width:'100%'}}
        value={selected}
        showSearch
        placeholder={placeholder || 'Select post type'}
        optionFilterProp="children"
        onChange={onSelect}
        filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
        {data.map(function (item) {
            return <Option title={item.name} key={item.id} value={item.id}>{item.name}</Option>
        })}
    </Select>;
};

SelectCategory.propTypes = {
    data: PropTypes.array,
    onSelect: PropTypes.func.isRequired,
    placeholder: PropTypes.string
};

export {SelectCategory};