import React, {Component} from 'react';
import {AppModal} from "./AppModal";
import Link from "next/dist/client/link";

class GetStarted extends Component {
    state = {
        getStartedModal: true
    };

    render() {
        return this.state.getStartedModal ?
            <AppModal width={850} show={this.state.getStartedModal} onClose={() => {
                window.localStorage.setItem('get_started_', true);
                this.setState({getStartedModal: false})
            }}>
                <div className="greet-modal-content">
                    <h2>Greetings, We are very delighted to see you here.</h2>
                    <h1>Get started with a quick registration at rojgarisanjal</h1>
                    <p>Rojgarisanjal offers you the advantage to expose yourself or your company through out the
                        world
                        to satisfy your job requirements for absolutely FREE.</p>
                    <hr/>
                    <div className="row reg_type">
                        <div className="col-sm-3 col-6">
                            <div className="steps">
                                <img src="/images/undraw_account_490v.svg" alt=""/>
                                <h4>Register an Account</h4>
                            </div>
                        </div>
                        <div className="col-sm-3 col-6">
                            <div className="steps">
                                <img src="/images/undraw_resume_folder_2_arse.svg" alt=""/>
                                <h4>Complete profile or resume</h4>
                            </div>
                        </div>
                        <div className="col-sm-3 col-6">
                            <div className="steps">
                                <img src="/images/undraw_multitasking_hqg3.svg" alt=""/>
                                <h4>Post Job | Publish Resume</h4>
                            </div>
                        </div>

                        <div className="col-sm-3 col-6">
                            <div className="steps">
                                <img src="/images/undraw_interview_rmcf.svg" alt=""/>
                                <h4>Hire Employee | Get Hired</h4>
                            </div>
                        </div>
                    </div>
                    <Link href={'/auth/register'}>
                        <a onClick={() => {
                            window.localStorage.setItem('get_started_', true);
                            this.setState({getStartedModal: false})
                        }} href="/auth/register" className={'btn btn-primary'}>Register FREE ACCOUNT</a>
                    </Link>
                    <br/>
                </div>
            </AppModal> : null;
    }

    getRouteName() {
        return this.props.router.route.split('/')[1] || '';
    }

    componentDidMount() {
        if ((window.localStorage && window.localStorage.getItem('get_started_')) || this.props.isAuthenticated === true) {
            this.setState({getStartedModal: false});
        }
    }
}

export {GetStarted};