import React from 'react';
import PropTypes from 'prop-types';

const LogoGenerator = ({ name, height = 'inherit', isGrid = false, aspectRatio = true }) => {
  const color = ['#F6941C', '#10B04A'];
  return <div
    className={'company_profile_image_gen' + (isGrid ? ' grid_pig' :'')}
    style={{ background: color[Math.floor((Math.random() * 2) + 0)], width: '100%', aspectRatio:aspectRatio?'1/1':'auto', 
 fontSize: 40,marginBottom:10 }}>
    {name.charAt(0).toUpperCase()}
  </div>
};


LogoGenerator.propTypes = {
  name: PropTypes.string.isRequired,
};

export { LogoGenerator };