import React from "react";
import PropTypes from 'prop-types';
import Link from "next/link";
import { stringLimit, ucFirst } from "../../utils/helpers";
import { connect } from "react-redux";
import { removeFavourite } from "../../store/actions";
import { Button, Dropdown, Icon, Modal, Popover, Menu } from "antd";
import { redirect } from "../../utils/redirect";
import { LogoGenerator } from ".";

const { confirm } = Modal;


const JobGridComponent = ({ item, cardType = null, removeFavourite, showFavouriteActionMenu, isSlim = false }) => {
    const color = ['#F6941C', '#10B04A'];

    let remainDaysRaw = (/\d./.exec(item.expired_at.human));
    let remainDayClass = 'success';
    if (remainDaysRaw && remainDaysRaw.length) {
        if (parseInt(remainDaysRaw[0]) < 3 && /.*days.*/.test(remainDaysRaw.input)) {
            remainDayClass = 'error';
        } else if (parseInt(remainDaysRaw[0]) < 5 && /.*days.*/.test(remainDaysRaw.input)) {
            remainDayClass = 'warning';
        }
    } else if (item.expired_at.human === 'in a day') {
        remainDayClass = 'error';
    }
    if (item.is_expired === true) {
        remainDayClass = 'error';
    }
    return (<div style={{ cursor: 'pointer' }} onClick={() => {
        redirect('/job/detail/[slug]', {}, `/job/detail/${item.slug}`, true);
    }} className="job-grid-view">
        <div className="d-flex justify-content-between">
            <h2 title={item.title}><Link href={`/job/detail/[slug]`} as={`/job/detail/${item.slug}`}><a
                style={{ color: '#555', fontSize: 17 }}
                href={`/job/detail/${item.slug}`}>{stringLimit(item.title, 30)}</a></Link></h2>

            {showFavouriteActionMenu && item.reminder_notes ? <Dropdown overlay={<Menu>
                {item.reminder_notes && item.reminder_notes.length ? <Menu.Item>
                    <Popover content={<div>{item.reminder_notes}</div>} title="Note">
                        <a target="_blank" href="" onClick={(e) => {
                            e.preventDefault();
                        }}>
                            View Note
                            </a>
                    </Popover>
                </Menu.Item> : null}
            </Menu>}>
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    {/*<Icon style={{fontSize: 18}} type="down"/>*/}
                    <i style={{ fontSize: 18 }} className="fas fa-chevron-down" />
                </a>
            </Dropdown> : null}

        </div>

        <div className="company_info">
            <div className="c_logo">
                {item.company.logo !== 'https://rojal.s3.ap-south-1.amazonaws.com/rojal/logo/logo_placeholder.jpg' ? <img src={item.company.logo} alt="" /> : <LogoGenerator aspectRatio={false} isGrid={true} name={item.company.name}/>}
            </div>
            <div className="info">
                <h5 title={item.company.name}>{stringLimit(item.company.name, 30)}</h5>
                {item.company.domain ?
                    <a href={'http://www.' + item.company.domain.replace(new RegExp('\\.*www.', 'gi'), '').replace(/^http?.:\/\//, '')}
                        target='_blank'>
                        <i className="fas fa-external-link-alt" /> {item.company.domain.toLowerCase().replace(new RegExp('\\.*www.', 'gi'), '')}
                    </a> : null}
            </div>
        </div>

        <br />

        {!isSlim ? <div className="row">
            <div title={item.job_location} className="col-6">
                <h4>Location</h4>
                <p style={{ marginTop: 0, fontSize: 12 }}>{stringLimit(item.job_location, 16)}</p>
            </div>
            <div title={item.education} className="col-6">
                <h4>Education</h4>
                <p style={{ marginTop: 0, fontSize: 12 }}>{stringLimit(item.education, 16) || '-'}</p>
            </div>
        </div> : null}


        {!isSlim ? <div className="grid-footer">
            <div className={'expiry-days'}>
                <span
                    className={'expiry ' + remainDayClass}>{ucFirst(item.expired_at.human)}</span>
            </div>
            {cardType === 'MY_FAVOURITE_LIST' ? <div className={'user-action'}>
                <Button onClick={() => {
                    redirect('/job/detail/[slug]', {}, `/job/detail/${item.slug}`);

                    // jobGeneralState({props: 'selectedApplicant', value: item});
                    // userDetails(item.id, {}, true);
                }}>Open</Button> &nbsp;&nbsp;
                    <Button type="danger" onClick={(e) => {
                    e.stopPropagation();
                    confirm({
                        title: 'Remove from favourite list?',
                        content: 'Click the Yes button if you want to remove this job from your favourite list',
                        okText: 'Yes',
                        cancelText: 'No',
                        onOk() {
                            removeFavourite(item.favourite_id, 'job')
                        }
                    });
                }}><Icon type="delete" />
                        Remove</Button>
            </div> : <div className={'user-action'}>
                    <a href="#"><i className="far fa-heart" /></a>
                    <Link href={`/job/detail/[slug]`} as={`/job/detail/${item.slug}`}>
                        <a href={`/job/detail/${item.slug}`}>
                            <i className="far fa-eye" />
                        </a>
                    </Link>
                </div>}
        </div> : null}
    </div>
    );
};

const mapStateToProps = (state) => {
    return {
        // group: state.group
    }
};
JobGridComponent.propTypes = {
    item: PropTypes.object.isRequired
};
const JobGrid = connect(mapStateToProps, { removeFavourite })(JobGridComponent);

export { JobGrid };