import React from "react";
import Link from "next/link";
import { stringLimit } from "../../utils/helpers";
import { Button, Modal, Tooltip } from "antd";
import { redirect } from "../../utils/redirect";
import { LogoGenerator } from ".";

const { confirm } = Modal;

const CompanyGrid = ({ item, followCompany, ica, isFavourite }) => {
    const color = ['#F6941C', '#10B04A'];


    return (<div style={{ cursor: 'pointer' }} onClick={() => {
        redirect('/company/detail/[slug]', {}, `/company/detail/${item.slug}`, true);
    }} className="job-grid-view">
        <div className="company_info">
            <div className="c_logo">
                {item.logo !== 'https://rojal.s3.ap-south-1.amazonaws.com/rojal/logo/logo_placeholder.jpg' ? <img src={item.logo} alt="" /> : <LogoGenerator aspectRatio={false} isGrid={true} name={item.name}/>}
            </div>
            <div className="info">
                <h5 title={item.name}><Link href={`/company/detail/[slug]`} as={`/company/detail/${item.slug}`}><a
                    style={{ color: '#00b365' }}
                    href={`/company/detail/${item.slug}`}>{stringLimit(item.name, 30)}</a></Link></h5>
                {item.domain ?
                    <a href={'http://www.' + item.domain.replace(new RegExp('\\.*www.', 'gi'), '').replace(/^http?.:\/\//, '')}
                        rel="noreferrer" target='_blank'>
                        <i className="fas fa-external-link-alt" /> {item.domain.substr(0, 30).toLowerCase().replace(new RegExp('\\.*www.', 'gi'), '') + '..'}
                    </a> : <a href={''}><i className="fas" />&nbsp; </a>}
            </div>
        </div>

        <br />

        <div className="row">
            <div className="col-12">
                <h4>Location</h4>
                <p style={{ marginTop: 0, fontSize: 12 }}>{item.p_address}</p>
            </div>
        </div>


        <div className="grid-footer">
            <div className={'expiry-days'}>
                <span
                    className={'success'}>{item.job_count ? (item.job_count + (item.job_count > 1 ? ' openings' : ' opening')) : '0 opening'}</span>
            </div>
            <div className={'user-action'}>
                {!ica && !isFavourite ?
                    <Tooltip title={item.is_followed === true ? 'Following' : 'Click to follow company'}>
                        <a className={item.is_followed === true ? 'active' : ''} href="" onClick={(e) => {
                            e.preventDefault();
                            followCompany({ type: item.is_followed === true ? 0 : 1, company_id: item.id });
                        }}>
                            <i className="far fa-heart" />
                        </a>
                    </Tooltip> : (isFavourite ? <Button type="danger" onClick={(e) => {
                        e.stopPropagation();
                        confirm({
                            title: 'Un follow company?',
                            content: 'Click the Yes button if you want to remove this company from your favourite job',
                            okText: 'Yes',
                            cancelText: 'No',
                            onOk: followCompany
                        });
                    }}> Un Follow</Button> : null)}
            </div>
        </div>
    </div>
    );
};

export { CompanyGrid };