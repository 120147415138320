import {Modal} from "antd";
import React from "react";
import PropTypes from "prop-types";

const AppModal = (props) => {
    let {show, onOk, onClose, errorModal, width, noClose, modalClass} = props;

    if (!modalClass)
        modalClass = '';

    return (<Modal
        width={width || 520}
        visible={show}
        title="Title"
        className={'app_modal_container ' + (errorModal ? 'app_modal_error' : '') + (noClose ? ' no_close' : '') + (' ' + modalClass)}
        onOk={onOk}
        onCancel={onClose}
        footer={[]}>
        {props.children}
    </Modal>);
};

AppModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onOk: PropTypes.func,
    errorModal: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    noClose: PropTypes.bool,
    modalClass: PropTypes.string,
};


export {AppModal};