import React, { Component } from 'react'
import { Spin, Icon, Tabs, Input, Select, Checkbox, DatePicker, Button } from 'antd';
const { TabPane } = Tabs;
import { connect } from "react-redux";
const searchClient = algoliasearch('2C08NZNUBI', '0f90489d1a0c08ba029cfad9d2367e2d');
import { jobInputChange, generateStateUpdate, publishJobPost, jobPostRequirementIncrement, updateJobPost } from './../../store/actions'
import { InstantSearch } from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import SearchCategoryAlgo from './SearchCategoryAlgo'
import { jobExperienceType, ucWords, academicQualificationHead, ucFirst, isJobReadyForPublish } from '../../utils/helpers';
const Option = Select.Option;
import moment from 'moment';
import { JOB_GENERAL_STATE } from '../../store/ActionTypes';

class PostJobFormComponent extends Component {

    static isAboutComplete(aboutJob) {
        return !(!aboutJob.availability || !aboutJob.education || !aboutJob.experience_year || !aboutJob.job_location || !aboutJob.vacancy_deadline || !aboutJob.title || !aboutJob.preferred_gender || !aboutJob.post_label || (parseInt(aboutJob.salary) > parseInt(aboutJob.salary_to)) || !aboutJob.sub_category_id || !aboutJob.openings);
    }

    static is2ndTabComplete(aboutJob) {
        return (aboutJob.descriptions && aboutJob.requirements && aboutJob.descriptions.length && aboutJob.descriptions[0].length && aboutJob.requirements.length && aboutJob.requirements[0].length);
    }


    // isJobReadyForPublish(formData) {
    //     let output = false;
    //     try {
    //         ['availability', 'category_id', 'descriptions', 'education', 'experience_year', 'job_location', 'openings', 'post_label', 'preferred_gender', 'requirements', 'title', 'vacancy_deadline'].map((item) => {
    //             if (item === 'requirements' || item === 'descriptions') {
    //                 output = formData[item].length && formData[item][0].length > 0;
    //             } else {
    //                 if (typeof formData[item] === 'number')
    //                     output = formData[item];
    //                 else
    //                     output = formData[item] && formData[item].length > 0
    //             }
    //             if (output === false) {
    //                 throw new Error('');
    //             }
    //         });
    //     } catch (e) {
    //         return false;
    //     }
    //     return true;
    // }


    jobRequirementList() {
        const { jobInputChange, jobPostRequirementIncrement, job } = this.props;
        const { item } = job;
        return (
            <div className="media-body ">

                <span className="tr-title">
                    <i className="far fa-thumbs-up" aria-hidden="true" /> List Your Job Description:

                    <span style={{ float: 'right', cursor: 'pointer' }}
                        onClick={() => {
                            jobPostRequirementIncrement('descriptions');

                        }}
                        id="edu-clone"
                        className="icon clone">
                        <i className="fa fa-plus" aria-hidden="true" />
                    </span>
                </span>
                <div className="additem">
                    <div className="code-edit">
                        {item.descriptions.map((item, i) => {
                            return <div className="form-group" key={i}>
                                <Input onChange={(e) => {
                                    jobInputChange({ props: i, value: ucFirst(e.target.value.trimStart()), type: 'descriptions' });
                                }} value={ucFirst(item)} placeholder="" /></div>
                        })}
                    </div>
                </div>

                <br />

                <span className="tr-title"><i className="far fa-thumbs-up"
                    aria-hidden="true" /> List Your Job Requirements:
                    <span style={{ float: 'right', cursor: 'pointer' }}
                        onClick={() => {
                            jobPostRequirementIncrement('requirements');
                        }}
                        id="edu-clone" className="icon clone">
                        <i className="fa fa-plus" aria-hidden="true" />
                    </span>
                </span>
                <div id="add-edu" className="additem">

                    <div className="code-edit">
                        {item.requirements.map((item, i) => {
                            return <div className="form-group" key={i}><Input onChange={(e) => {
                                jobInputChange({ props: i, value: ucFirst(e.target.value.trimStart()), type: 'requirements' });
                            }} value={ucFirst(item)} placeholder="" /></div>
                        })}
                    </div>
                </div>
            </div>
        );
    }


    basicJobRequirements() {
        const { job, publishJobPost, updateJobPost } = this.props;
        const { item, previewJob, publishing } = job;
        return (<div className="tab-contents-container">
            {this.jobRequirementList()}
            <hr />
            <Button onClick={() => {
                PostJobFormComponent.navigateToScrollTop();
                this.props.generateStateUpdate(JOB_GENERAL_STATE, { props: 'activeTabKey', value: '1' });
            }} type="default">
                Previous
            </Button> &nbsp;
            <Button loading={publishing}
                disabled={!isJobReadyForPublish(item) && previewJob}
                onClick={() => {
                    if (item.is_update && updateJobPost) {
                        updateJobPost();
                    } else if (publishJobPost) {
                        publishJobPost();
                    }
                }}
                type="primary">
                {item.is_update ? 'Update Your Post' : 'Publish Your Job'}
            </Button>
        </div>);
    }



    aboutJobInfoUpdateForm() {
        const { job, jobInputChange, publishJobPost } = this.props;
        const { item, publishing } = job;

        return (<div className="tab-contents-container">
            <div className="row">
                <div className="col-sm-3">
                    <label><sup style={{ fontSize: 15, color: '#e04427' }}>*</sup>Job Title<span
                        className="pull-right">:</span> </label>
                </div>
                <div className="col-sm-8">
                    <div className="form-group">
                        <Input value={(item.title)} placeholder="Marketing manager wanted" onChange={(e) => {
                            jobInputChange({ props: 'title', value: (e.target.value) });
                        }} />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-3">
                    <label><sup style={{ fontSize: 15, color: '#e04427' }}>*</sup>Job Function<span
                        className="pull-right">:</span>
                    </label>
                </div>
                <div className="col-sm-8">
                    <div className="form-group">
                        <InstantSearch searchClient={searchClient} indexName="dev_ROJAL">
                            <SearchCategoryAlgo mode={'default'}
                                previousSelectedCategory={[item.category]}
                                selectedCategory={item.sub_category_id}
                                onSelectCategory={(value) => {
                                    jobInputChange({ props: 'sub_category_id', value })
                                }} />
                        </InstantSearch>

                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-3">
                    <label><sup style={{ fontSize: 15, color: '#e04427' }}>*</sup>Availability<span
                        className="pull-right">:</span> </label>
                </div>
                <div className="col-sm-8">
                    <div className="form-group">
                        <Select placeholder={'Full Time'} value={item.availability} defaultValue={item.availability}
                            onChange={(value) => {
                                jobInputChange({ props: 'availability', value });
                            }}>
                            <Option value={'1'}>Full Time</Option>
                            <Option value={'0'}>Part Time</Option>
                            <Option value={'2'}>Freelance</Option>
                        </Select>
                    </div>
                </div>
            </div>


            <div className="row">
                <div className="col-sm-3">
                    <label><sup style={{ fontSize: 15, color: '#e04427' }}>*</sup>Level<span
                        className="pull-right">:</span> </label>
                </div>
                <div className="col-sm-8">
                    <div className="form-group">
                        <Select value={item.post_label} defaultValue={item.post_label} disabled={false}
                            onChange={(value) => {
                                if (value === "1" && !item.experience_year) {
                                    jobInputChange({ props: 'experience_year', value: "0" });
                                }
                                jobInputChange({ props: 'post_label', value });
                            }}>
                            <Option value="4">Senior</Option>
                            <Option value="3">Mid - Level</Option>
                            <Option value="2">Junior</Option>
                            <Option value="1">Intern</Option>
                        </Select>
                    </div>
                </div>
            </div>


            <div className="row">
                <div className="col-sm-3">
                    <label><sup style={{ fontSize: 15, color: '#e04427' }}>*</sup>Min Experience Year<span
                        className="pull-right">:</span>
                    </label>
                </div>
                <div className="col-sm-8">
                    <div className="form-group">
                        <Select value={item.experience_year} defaultValue={item.experience_year} disabled={false}
                            onChange={(value) => {
                                jobInputChange({
                                    props: 'experience_year',
                                    value
                                });
                            }}>
                            {Object.keys(jobExperienceType()).map(function (item, i) {
                                return <Option key={i} value={item}>{jobExperienceType(item)}</Option>;
                            })}
                        </Select>
                    </div>
                </div>
            </div>

            <div className="row mb-3 mt-2">

                <div className="col-sm-3">
                    <label>Offered Salary<span className="pull-right">:</span>
                    </label>
                </div>
                <div className="col-sm-8">
                    <div className="row">
                        {!item.negotiable ? <div className="col-5">
                            <div className="form-group">
                                <Input placeholder="From: 40000" value={item.salary}
                                    onChange={(e) => {
                                        if (e.target.value.match(/^\d+$/) || !e.target.value.length) {
                                            jobInputChange({ props: 'salary', value: e.target.value });
                                        }
                                    }} />
                            </div>
                        </div> : null}

                        {!item.negotiable ? <div className={'col-1'}>
                            <div className="form-group mt-2">
                                To
                            </div>
                        </div> : null}
                        {!item.negotiable ? <div className="col-6">
                            <div className="form-group">
                                <Input className={parseInt(item.salary) > parseInt(item.salary_to) ? 'has-error' : ''}
                                    disabled={!item.salary.toString().length}
                                    placeholder="To: 60000" value={item.salary_to}
                                    onChange={(e) => {
                                        if (e.target.value.match(/^\d+$/) || !e.target.value.length) {
                                            jobInputChange({ props: 'salary_to', value: e.target.value });
                                        }
                                    }} />
                                {parseInt(item.salary) > parseInt(item.salary_to) ? <span className={'error'}><small>Should be more</small></span> : null}
                            </div>
                        </div> : null}

                        <div className="col-12">
                            <Checkbox style={{ marginTop: 15 }} checked={item.negotiable} onChange={(e) => {
                                jobInputChange({
                                    props: 'negotiable',
                                    value: !item.negotiable,
                                });
                            }}>Negotiable</Checkbox>
                        </div>
                    </div>
                </div>

            </div>


            <div className="row">
                <div className="col-sm-3">
                    <label><sup style={{ fontSize: 15, color: '#e04427' }}>*</sup>Job Location<span
                        className="pull-right">:</span>
                    </label>
                </div>
                <div className="col-sm-8">
                    <div className="form-group">
                        <Input placeholder="eg: Kamalpokhari near city center" value={ucWords(item.job_location)}
                            onChange={(e) => {
                                jobInputChange({ props: 'job_location', value: ucWords(e.target.value) });
                            }} />
                    </div>
                </div>
            </div>


            <div className="row">
                <div className="col-sm-3">
                    <label title={'Academic Qualification'}><sup style={{ fontSize: 15, color: '#e04427' }}>*</sup>Academic
                        Qua.<span className="pull-right">:</span>
                    </label>
                </div>
                <div className="col-sm-8">
                    <div className="form-group">
                        <Select
                            style={{ width: '100%' }}
                            value={item.education}
                            showSearch
                            placeholder={'Select degree type'}
                            optionFilterProp="children"
                            onChange={(value) => {
                                jobInputChange({ props: 'education', value: ucFirst(value) });
                            }}>
                            {Object.keys(academicQualificationHead('', true)).map(function (head, key) {
                                return <Option title={academicQualificationHead(head, true)}
                                    key={key + 100}
                                    value={academicQualificationHead(head, true)}>{academicQualificationHead(head, true)}</Option>
                            })}
                        </Select>


                        {/*<Input placeholder="eg: Bachelors or +2" value={ucFirst(item.education)} onChange={(e) => {*/}
                        {/*jobInputChange({props: 'education', value: ucFirst(e.target.value)});*/}
                        {/*}}/>*/}
                    </div>
                </div>
            </div>


            <div className="row">
                <div className="col-sm-3">
                    <label><sup style={{ fontSize: 15, color: '#e04427' }}>*</sup>No of Openings<span className="pull-right">:</span>
                    </label>
                </div>
                <div className="col-sm-8">
                    <div className="form-group">
                        <Input placeholder="" value={item.openings} onChange={(e) => {
                            if (e.target.value <= 100 && e.target.value.match(/^\d+$/) || !e.target.value.length) {
                                jobInputChange({ props: 'openings', value: e.target.value });
                            }
                        }} />
                    </div>
                </div>
            </div>


            <div className="row">
                <div className="col-sm-3">
                    <label><sup style={{ fontSize: 15, color: '#e04427' }}>*</sup>Deadline<span
                        className="pull-right">:</span>
                    </label>
                </div>
                <div className="col-sm-8">
                    <div className="calendar">
                        <div className="form-group">
                            <DatePicker allowClear={false}
                                value={item.vacancy_deadline.length ? moment(item.vacancy_deadline) : null}
                                disabledDate={(currentDate) => {
                                    let nowPlus30 = new Date();
                                    nowPlus30.setDate(nowPlus30.getDate() + 30);
                                    return currentDate < new Date() || currentDate > nowPlus30;
                                }}
                                onChange={(date) => {
                                    if (date)
                                        jobInputChange({
                                            props: 'vacancy_deadline',
                                            value: date.format('YYYY-MM-DD')
                                        })
                                }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-3">
                    <label><sup style={{ fontSize: 15, color: '#e04427' }}>*</sup>Preferred Gender<span
                        className="pull-right">:</span> </label>
                </div>
                <div className="col-sm-8">
                    <div className="form-group">
                        <Select defaultValue={item.preferred_gender} disabled={false} onChange={(value) => {
                            jobInputChange({ props: 'preferred_gender', value });
                        }}>
                            <Option value="male">Male</Option>
                            <Option value="female">Female</Option>
                            <Option value="any">Any</Option>
                        </Select>
                    </div>
                </div>
            </div>


            <hr />
            <Button disabled={!PostJobFormComponent.isAboutComplete(item)} onClick={() => {
                PostJobFormComponent.navigateToScrollTop();

                // this.myRefPageTop.current.scrollIntoView(true);
                this.props.generateStateUpdate(JOB_GENERAL_STATE, { props: 'activeTabKey', value: '2' });

            }} type="default">
                Next
            </Button>
            &nbsp;&nbsp;&nbsp;
            {isJobReadyForPublish(item) ?
                <Button loading={publishing}
                    disabled={!isJobReadyForPublish(item) && previewJob}
                    onClick={() => {
                        if (item.is_update && updateJobPost) {
                            updateJobPost();
                        } else if (publishJobPost) {
                            publishJobPost();
                        }
                    }}
                    type="primary">
                    {item.is_update ? 'Update Your Post' : 'Publish Your Job'}
                </Button> : null}


        </div>
        );
    }

    render() {
        const { loading, job } = this.props;
        const { saving, previewJob, addGuestJob } = job;
        const isJobAboutComplete = PostJobFormComponent.isAboutComplete(job.item);

        return (<Spin wrapperClassName={'company_incomplete'}
            indicator={<Icon type="warning" />}
            spinning={loading}
            tip={'Please complete your company profile. Before you post a job.'}>
            <div ref={this.myRefPageTop} role="tabpanel"
                className="tab-pane fade in section active show edit-resume section"
                id="edit-resume">

                {previewJob === true ? <Button icon={'desktop'} className={'preview-job'} loading={saving}
                    size={'small'} onClick={() => {
                        window.open('/job/detail-mock?mock_post_id=' + job.item.id + '&_rg_cd=' + job.item.job_code, '_blank');
                    }}>{'Preview'}
                </Button> : saving === true ?
                    <Button className={'preview-job'} loading={saving}
                        size={'small'}>{previewJob ? 'Preview' : 'Saving...'}
                    </Button> : null}

                <ul className="tr-list resume-info">
                    <div id="scroll-here-tab" />

                    <Tabs onTabClick={(key) => {
                        if (isJobAboutComplete)
                            this.props.generateStateUpdate(JOB_GENERAL_STATE, { props: 'activeTabKey', value: key });

                    }} activeKey={job.activeTabKey}>
                        <TabPane tab={
                            <span>
                                <Icon type="user-add" />
                                About Job
                                {!isJobAboutComplete ?
                                    <small className={'resume-status complete'}><Icon type="warning" /> In complete
                                    </small> :
                                    <small className={'resume-status complete'}><Icon type="check" /> Complete</small>}
                            </span>
                        } key="1">
                            {this.aboutJobInfoUpdateForm()}
                        </TabPane>

                        <TabPane tab={
                            <span style={{ cursor: !isJobAboutComplete ? 'not-allowed' : '' }}>
                                <Icon type="form" />
                                Descriptions & Requirements
                                {!PostJobFormComponent.is2ndTabComplete(job.item) ?
                                    <small className={'resume-status complete'}><Icon type="warning" /> In complete
                                    </small> :
                                    <small className={'resume-status complete'}><Icon type="check" /> Complete</small>}
                            </span>
                        } key="2">
                            {this.basicJobRequirements()}
                        </TabPane>
                    </Tabs>
                </ul>

            </div>
        </Spin>
        )
    }

    static navigateToScrollTop() {
        const element = document.getElementById("scroll-here-tab");
        element.scrollIntoView({ behavior: "auto", block: "center"/*nearest*/, inline: "nearest" });
    }
}

const mapStateToProps = (state) => {
    return {
        job: state.job
    }
};
const PostJobForm = connect(mapStateToProps, { jobInputChange, generateStateUpdate, publishJobPost, jobPostRequirementIncrement, updateJobPost })(PostJobFormComponent);

export { PostJobForm };