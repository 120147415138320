import React, { Component } from 'react';
import { Button, Icon, Modal, Spin, Tabs, Tooltip } from "antd";
import { UserCVDemo } from "./UserCVDemo";
import { AppModal } from "./AppModal";
import { RESUME_GENERAL_STATE } from "../../store/ActionTypes";
import { UserCV } from "./UserCV";

const { TabPane } = Tabs;
let jsPDF = null;
let html2canvas = null;

if (typeof window !== "undefined") {
    import("jspdf").then(module => {
        jsPDF = module.default;
    });
    import('html2canvas').then(module => {
        html2canvas = module.default;
    });
}


class CvModal extends Component {
    state = {
        activeKey: '1',
        scrollTop: 0,
        downloadingResume: false,
        downloadFileName: '',
        frameLoaded: true
    };

    constructor() {
        super();
        this.cvContainer = React.createRef();
        this.cvLangSection = React.createRef();
        this.cvDeclaration = React.createRef();
        this.cvScrollingContainer = React.createRef();
        this.cvAboutSection = React.createRef();
        this.cvCareerObject = React.createRef();
        this.cvSkill = React.createRef();
        this.cvAcademic = React.createRef();
        this.cvExperience = React.createRef();

        this.cvScrollingContainer = React.createRef();
        this.cvMainContainer = React.createRef();
    }

    downloadResumeSample(e) {
        this.setState({ downloadingResume: true });
        html2canvas(document.querySelector('#cv_user_paper'), {
            allowTaint: true,
            useCORS: true,
            scale: 2,
            scrollX: 0,
            scrollY: -this.state.scrollTop,
            onclone: function () {
            }
        }).then(canvas => {
            var link = document.createElement('a');
            link.download = `${this.state.downloadFileName ? this.state.downloadFileName : 'demo'}-resume-rojgarisanjal.jpg`;
            link.href = canvas.toDataURL('image/jpg');
            link.click();
        }).finally(_ => {
            this.setState({ downloadingResume: false })
        });
    }

    tabChange(activeKey) {
        this.setState({ activeKey });
        switch (activeKey) {
            case '1':
                if (this.cvAboutSection.current)
                    this.cvMainContainer.current.scrollTop = this.cvAboutSection.current.offsetTop;
                break;
            case '2':

                if (this.cvCareerObject.current)
                    this.cvMainContainer.current.scrollTop = this.cvCareerObject.current.offsetTop;
                break;
            case '3':
                if (this.cvLangSection.current)
                    this.cvMainContainer.current.scrollTop = this.cvLangSection.current.offsetTop;
                break;
            case '4':
                if (this.cvSkill.current)
                    this.cvMainContainer.current.scrollTop = this.cvSkill.current.offsetTop;
                break;
            case '5':
                if (this.cvAcademic.current)
                    this.cvMainContainer.current.scrollTop = this.cvAcademic.current.offsetTop;
                break;
            case '6':
                if (this.cvExperience.current)
                    this.cvMainContainer.current.scrollTop = this.cvExperience.current.offsetTop;
                break;
            case '7':
                if (this.cvDeclaration.current)
                    this.cvMainContainer.current.scrollTop = this.cvDeclaration.current.offsetTop;
                break;
        }
    }

    static checkCompleteStatus(isComplete) {
        return !!isComplete ?
            <small className={'resume-status complete'}>
                <Icon type="check" /> Complete
            </small>
            : <small className={'resume-status complete'} style={{ color: 'red' }}>
                <Icon type="close" /> Incomplete
            </small>
    }


    tabItemsRender() {
        const { resumeInfo, isSample } = this.props;
        return !isSample ? <Tabs onChange={this.tabChange.bind(this)} defaultActiveKey={this.state.activeKey}
            activeKey={this.state.activeKey}>
            <TabPane tab={
                <span>
                    <Icon type="user-add" />
                  About Me
                        <small className={'resume-status complete'}>
                        <Icon type="check" />
                            Complete
                        </small>
                </span>
            } key="1">
            </TabPane>

            <TabPane disabled={!(!!resumeInfo.resume_info.career_objectives)} tab={
                <Tooltip placement="topLeft"
                    title="Languages, Special Qualifications, Salary Expectation etc.">
                    <span>
                        <Icon type="form" />Career Objective
                        {CvModal.checkCompleteStatus(!!resumeInfo.resume_info.career_objectives)}

                    </span>
                </Tooltip>
            } key="2">
            </TabPane>

            <TabPane
                disabled={(resumeInfo.resume_info.languages.length < 1)}
                tab={
                    <Tooltip placement="topLeft"
                        title="Languages, Special Qualifications, Salary Expectation etc.">
                        <span>
                            <Icon type="form" />Languages
                            {CvModal.checkCompleteStatus(!(resumeInfo.resume_info.languages.length < 1))}

                        </span>
                    </Tooltip>
                } key="3">
            </TabPane>

            <TabPane
                disabled={resumeInfo.resume_info.special_qualifications && resumeInfo.resume_info.special_qualifications.length < 1}
                tab={
                    <Tooltip placement="topLeft"
                        title="Languages, Special Qualifications, Salary Expectation etc.">
                        <span>
                            <Icon type="form" /> Skills
                            {CvModal.checkCompleteStatus(resumeInfo.resume_info.special_qualifications && resumeInfo.resume_info.special_qualifications.length > 0)}
                        </span>
                    </Tooltip>
                } key="4">
            </TabPane>

            <TabPane disabled={resumeInfo.academics.length < 1} tab={
                <span>
                    <Icon type="book" /> Academic Qualification
                    {CvModal.checkCompleteStatus(resumeInfo.academics.length > 0)}
                </span>
            } key="5">
            </TabPane>

            <TabPane disabled={resumeInfo.experiences.length < 1} tab={
                <span>
                    <Icon type="solution" /> Experiences
                    {CvModal.checkCompleteStatus(resumeInfo.experiences.length > 0)}
                </span>
            } key="6">
            </TabPane>

            <TabPane disabled={!(!!resumeInfo.resume_info.declaration)} tab={
                <Tooltip placement="topLeft"
                    title="Languages, Special Qualifications, Salary Expectation etc.">
                    <span>
                        <Icon type="form" /> Declarations
                        {CvModal.checkCompleteStatus(!!resumeInfo.resume_info.declaration)}
                    </span>
                </Tooltip>
            } key="7">
            </TabPane>
        </Tabs> :
            <Tabs onChange={this.tabChange.bind(this)} defaultActiveKey={this.state.activeKey}
                activeKey={this.state.activeKey}>
                <TabPane tab={
                    <span>
                        <Icon type="user-add" /> About Me
                    <small className={'resume-status complete'}>
                            <Icon type="check" />
                        Complete
                    </small>
                    </span>
                } key="1">
                </TabPane>

                <TabPane tab={
                    <Tooltip placement="topLeft"
                        title="Languages, Special Qualifications, Salary Expectation etc.">
                        <span>
                            <Icon type="form" />Career Objective
                        <small className={'resume-status complete'}>
                                <Icon type="check" />
                            Complete
                        </small>
                        </span>
                    </Tooltip>
                } key="2">
                </TabPane>

                <TabPane
                    tab={
                        <Tooltip placement="topLeft"
                            title="Languages, Special Qualifications, Salary Expectation etc.">
                            <span>
                                <Icon type="form" />Languages
                            <small className={'resume-status complete'}>
                                    <Icon type="check" />
                                Complete
                            </small>
                            </span>
                        </Tooltip>
                    } key="3">
                </TabPane>

                <TabPane
                    tab={
                        <Tooltip placement="topLeft"
                            title="Languages, Special Qualifications, Salary Expectation etc.">
                            <span>
                                <Icon type="form" /> Skills
                            <small className={'resume-status complete'}>
                                    <Icon type="check" />
                                Complete
                            </small>
                            </span>
                        </Tooltip>
                    } key="4">
                </TabPane>

                <TabPane tab={
                    <span>
                        <Icon type="book" />
                        Academic Qualification
<small className={'resume-status complete'}>
                            <Icon type="check" />
                            Complete
                        </small>
                    </span>
                } key="5">
                </TabPane>

                <TabPane tab={
                    <span>
                        <Icon type="solution" /> Experiences
                    <small className={'resume-status complete'}>
                            <Icon type="check" />
                        Complete
                    </small>
                    </span>
                } key="6">
                </TabPane>

                <TabPane tab={
                    <Tooltip placement="topLeft"
                        title="Languages, Special Qualifications, Salary Expectation etc.">
                        <span>
                            <Icon type="form" /> Declarations
                        <small className={'resume-status complete'}>
                                <Icon type="check" />
                            Complete
                        </small>
                        </span>
                    </Tooltip>
                } key="7">
                </TabPane>
            </Tabs>;
    }

    render() {
        const { appReducerGeneralStateChange, showModal, resumeInfo, isSample } = this.props;

        return <div className="app_general_modal resume_modal">

            <AppModal width={'800px'} show={showModal} onClose={() => {
            }} modalClass={'app_general_modal resume_modal'}>
                {showModal ? <ul className="tr-list resume-info">
                    <div id="scroll-here-tab" />
                    {this.tabItemsRender()}
                </ul> : null}

                <div ref={this.cvMainContainer} className={'cv_main_container'} style={{ overflow: (isSample ? 'scroll' : 'none'), backgroundColor: ('#666') }}>
                    <div style={{ scrollBehavior: 'smooth' }} onScroll={(e) => {
                        this.setState({ scrollTop: e.target.scrollTop });
                    }} className={"cv_container " + (isSample ? 'is_sample_cv' : '')} ref={this.cvContainer}>
                        {isSample ? <UserCVDemo cvAboutSection={this.cvAboutSection}
                            cvAcademic={this.cvAcademic}
                            cvCareerObject={this.cvCareerObject}
                            cvDeclaration={this.cvDeclaration}
                            cvExperience={this.cvExperience}
                            cvSkill={this.cvSkill}
                            cvScrollingContainer={this.cvScrollingContainer}
                            cvLangSection={this.cvLangSection} /> :

                            <Spin spinning={this.state.frameLoaded} tip="Please wait while we prepare your CV">
                                <iframe id={'PDF_CONTAINER'} onLoad={() => {
                                    this.setState({ frameLoaded: false })
                                }} height="500" width="100%"
                                    src={process.env.DOWNLOADPATH + `/cv/${resumeInfo.personal.id}/download`}
                                    frameBorder="1" />
                            </Spin>}

                    </div>
                </div>

                <div className="footer_button">
                    <div>
                        <Button onClick={() => {
                            appReducerGeneralStateChange(RESUME_GENERAL_STATE, {
                                props: 'downloadResumeModal',
                                value: false
                            })
                        }}>Close</Button>
                        &nbsp;
                        {isSample ? <Button type={'primary'} onClick={() => {
                            window.open(process.env.DOWNLOADPATH + '/cv/sample/new/test','_blank');
                            
                            // document.querySelector('.cv_main_container').scroll(0, 0);
                            // let $this = this;
                            // setTimeout(function () {
                            //     $this.downloadResumeSample();
                            // }, 700);
                        }} loading={this.state.downloadingResume}>Download CV</Button> : null}
                    </div>
                </div>
            </AppModal>
        </div>

    }

}

export { CvModal };