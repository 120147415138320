import React from "react";

const ExpertyFieldsCategory = ({categories, icon = true, separater = '|', iconColor = 'white'}) => {
    return <span className={'expertise_field_section'}>
        {icon ? <span><i style={{color: iconColor}} className="fa fa-briefcase"/>&nbsp;</span> : null}
        <ul>
            {categories.map((item, i) => {
                return <li key={i}>{item.name}{i + 1 < categories.length ? <span>&nbsp; {separater}</span> : null}</li>
            })}
        </ul>
    </span>;
};

export {ExpertyFieldsCategory};
