import { connectAutoComplete } from 'react-instantsearch-dom';
import { Icon, Select } from 'antd';
import React from "react";

const { Option } = Select;

const SearchCategoryAlgolia = ({ hits, refine, onSelectCategory, selectedCategory, previousSelectedCategory = [], mode = 'multiple' }) => {

    previousSelectedCategory.map((cat) => {
        if (cat && !hits.filter(e => e.id === cat.id).length) {
            hits.push(cat);
        }
    });

    if (mode === 'multiple') {
        if (![null, undefined].includes(selectedCategory) && selectedCategory.length > 2)
            hits = hits.filter(function (hit) {
                return selectedCategory.includes(hit.id);
            });
    }

    return <Select
        style={{ width: '100%' }}
        className={'height_auto'}
        mode={mode}
        defaultValue={selectedCategory}
        showSearch
        placeholder="Search and select your expertise field"
        filterOption={() => {
            return true;
            // return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }}
        onSelect={(value) => {
            if (mode === 'multiple') {
                if (![null, undefined].includes(selectedCategory) && selectedCategory.length < 3) {
                    onSelectCategory(value);
                }
            } else {
                onSelectCategory(value, hits);
            }
        }}
        onDeselect={(value) => {
            onSelectCategory(value);
        }}
        onChange={() => {
        }}
        onFocus={() => {
        }}
        onBlur={() => {
        }}
        onSearch={(value) => {
            refine(value);
        }}>
        {hits.map((item, i) => {
            return <Option key={i} value={item.id}>{item.name}</Option>
        })}
    </Select>
};


export default connectAutoComplete(SearchCategoryAlgolia);