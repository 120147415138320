import React from 'react';
import PropTypes from 'prop-types';

import { availabilityType, concatCategories, slugify, ucFirst } from "../../utils/helpers";
import { NotFound } from "./NotFound";
import { ExpertyFieldsCategory } from "./ExpertyFieldsCategory";
import Link from "next/dist/client/link";


const EmployeeList = (props) => {

    const { data, openUserDetails } = props;
    if (!data.length) return <NotFound />;

    return data.map(function (item, i) {

        return (<div key={i} className="list_view">
            {<div className="job_logo employee_img">
                <img style={{ margin: 0 }}
                    src={item.image}
                    alt={item.full_name} onError={(e) => {
                        let userDefaultImage = 'https://rojal.s3.ap-south-1.amazonaws.com/defaults/profile_male.png';
                        if (item.gender === 'female') {
                            userDefaultImage = 'https://rojal.s3.ap-south-1.amazonaws.com/defaults/profile_female.png';
                        }
                        e.target.src = userDefaultImage;
                    }
                    } />
            </div>}
            <div className="job_details">
                <h2>
                    <Link as={`/profile/${concatCategories(item.category, true)}-${item.id}`}
                        href={`/profile/[userId]`}>
                        <a onClick={(e) => {
                            // e.preventDefault();
                            // openUserDetails(`/profile/${concatCategories(item.category, true)}-${item.id}`, '/profile/[userId]');
                        }} href={`/profile/${concatCategories(item.category, true)}-${item.id}`}>
                            <ExpertyFieldsCategory icon={false} categories={item.category} />

                            &nbsp;
                            <small>({item.full_name})</small>
                        </a>
                    </Link>
                </h2>
                {<p><i className="fas fa-map-marker-alt" /> {item.t_address},&nbsp;
                    <span><a style={{ color: '#777' }} href={"mailto:" + item.email}>&nbsp;&nbsp;
                        <i className="far fa-money-bill-alt" />Salary: {item.minimum_salary || 'Negotiable'}</a></span>
                </p>}
                <p>
                    <span>
                        <i className="far fa-clock" /> Profile Updated : {item.updated_time.str} ({ucFirst(item.updated_time.human)})</span>
                </p>
            </div>
            <div className="job_action">
                {item.availability || item.availability === 0 ?
                    <span className={'a_' + (item.availability)}>{availabilityType(item.availability)}</span> : null}
                {item.availability === '' ? <span className={'a_' + (item.availability)}>N/A</span> : null}
                <div>
                    <Link as={`/profile/${concatCategories(item.category, true)}-${item.id}`}
                        href={`/profile/[userId]`}>
                        <a onClick={(e) => {
                            e.preventDefault();
                            // Don't Delete
                            openUserDetails(`/profile/${concatCategories(item.category, true)}-${item.id}`, '/profile/[userId]');
                        }} href="" className={'btn btn-default btn-block'}>
                            Profile
                        </a>
                    </Link>
                </div>
            </div>
        </div>);
    });
};

EmployeeList.propTypes = {
    data: PropTypes.array.isRequired
};

export { EmployeeList };