import React from "react";
import {Button, Icon, Progress, Switch, Tooltip} from "antd";

const ResumeProgressBar = ({profilePercentage, resumeReadyForPublish, isResumePublished, publishResume}) => {
    return (<div className="resume_progress_bar">
        <Tooltip placement="top"
                 title={profilePercentage}>
            <Progress style={{height: '5px!important'}}
                      status={profilePercentage < 30 || !resumeReadyForPublish ? 'exception' : profilePercentage > 30 && profilePercentage < 50 ? '' : "success"}
                      percent={profilePercentage || 0}
                      showInfo={false}/>
        </Tooltip>
        {resumeReadyForPublish ? <div className={'r_info_p_info'}>
            <div className="r_info"><p style={{fontWeight: 'bold', color: '#777', lineHeight: 1, fontSize: 13}}>
                <span
                    style={{color: '#00b365'}}>Congratulations!!</span> {!isResumePublished ? 'Your resume is ready to get published.' : 'You resume has been published'}
            </p>
                <p style={{fontSize: 11, color: '#888', lineHeight: 1, paddingBottom: 5, paddingTop: 2}}>
                    {!isResumePublished ? 'Publish now, let companies know about you. You are more likely to get jobs this way.' : 'You are live and companies will now be able to visit your profile and might reach you anytime soon.'} </p>
            </div>
            <div className="p_now">
                <Tooltip placement="top"
                         title={isResumePublished && resumeReadyForPublish ? 'Resume published. Please make sure all your resume information are up to date.' : !resumeReadyForPublish ? "Publishing your resume is allowed only once you complete your resume's basic info." : 'Your resume has not been published yet and is hidden from all the searches.'}>
                    <Switch
                        disabled={!resumeReadyForPublish}
                        onChange={(val) => {
                            publishResume(val);
                        }}
                        checkedChildren={<Icon type="check"/>}
                        unCheckedChildren={<Icon type="close"/>}
                        defaultChecked={!!isResumePublished}/>
                </Tooltip>

                {/*<Button onClick={() => {*/}
                {/*publishResume(!isResumePublished);*/}
                {/*}} size={'small'} disabled={!resumeReadyForPublish}*/}
                {/*type={!isResumePublished ? "primary" : 'danger'}>{!isResumePublished ? 'Publish' : 'Un publish'}</Button>*/}

            </div>
        </div> : <div className={'r_info_p_info'}>
            <div className="r_info"><p style={{fontWeight: 'bold', color: '#777', lineHeight: 1, fontSize: 13}}>
                <span style={{color: '#dc3545'}}>OOPS!!</span> Resume not ready for publish</p>
                <p style={{fontSize: 11, color: '#888', lineHeight: 1, paddingBottom: 5, paddingTop: 2}}>Please make
                    sure you have updated all the required fields.(Email, Full Name, Expertise Field, Phone,
                    Address)</p></div>
            <div className="p_now">
                <Button size={'small'} disabled={true} type="primary">Publish</Button>
            </div>
        </div>}
    </div>);
};

export {ResumeProgressBar};
