import React from "react";
import PropTypes from 'prop-types';

const FormErrors = ({errors, formKey}) => {
    return errors[formKey] && errors[formKey].length ? (<p className={'form-error'}>{errors[formKey][0]}</p>) : null;
};

FormErrors.propTypes = {
    errors: PropTypes.object.isRequired,
    formKey: PropTypes.string.isRequired
};

export {FormErrors};