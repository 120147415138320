import {
    academicQualificationHead,
    availabilityType,
    currencyFormat,
    maritalStatus,
    ucFirst,
    ucWords,
    xTimes
} from "../../utils/helpers";
import React from "react";
import PropTypes from "prop-types";
import languages from './../../../src/config/languages';


const ResumeDetail = ({ academics, personal, resumeInfo, experiences }) => {
    return (<div style={{ height: '100%' }} role="tabpanel" className="tab-pane fade in section active show my-resume" id="resume">
        <ul className="tr-list resume-info">
            <li className="personal-deatils">
                <div className="icon">
                    <i className="fa fa-user-secret" aria-hidden="true" />
                </div>
                <div className="media-body">
                    <span className="tr-title">Personal Details</span>
                    <ul className="tr-list">
                        <li><span className="left" style={{ width: 120 }}>Full Name</span><span
                            className="middle">:</span> <span className="right">{personal.full_name || '-'}</span>
                        </li>
                        <li>
                            <span className="left" style={{ width: 120 }}>Email</span><span
                                className="middle">:</span> <span
                                    className="right">{personal.secondary_email ? personal.secondary_email : personal.email}</span>
                        </li>
                        <li>
                            <span className="left" style={{ width: 120 }}>Phone</span><span
                                className="middle">:</span> <span
                                    className="right">{personal.phone || '-'}</span>
                        </li>
                        <li><span className="left" style={{ width: 120 }}>Father's Name </span><span
                            className="middle">:</span> <span
                                className="right">{ucWords(personal.father_name) || '-'}</span></li>
                        <li>
                            <span className="left" style={{ width: 120 }}>Date of Birth</span>
                            <span className="middle">:</span> <span
                                className="right">{personal.dob || '-'}</span>
                        </li>
                        <li>
                            <span className="left" style={{ width: 120 }}>Nationality</span><span
                                className="middle">:</span>
                            <span className="right">{personal.nationality || '-'}</span></li>
                        <li><span className="left" style={{ width: 120 }}>Gender</span>
                            <span className="middle">:</span> <span
                                className="right">{ucFirst(personal.gender) || '-'}</span>
                        </li>
                        <li>
                            <span className="left" style={{ width: 120 }}>Permanent Address</span>
                            <span className="middle">:</span>
                            <span className="right">{personal.p_address || '-'}</span>
                        </li>

                        <li><span className="left" style={{ width: 120 }}>Temporary Address</span><span
                            className="middle">:</span>
                            <span className="right">{personal.t_address || '-'}</span>
                        </li>
                        <li><span className="left" style={{ width: 120 }}>Marital Status</span>
                            <span
                                className="middle">:</span>
                            <span
                                className="right">{personal.marital_status ? maritalStatus(personal.marital_status) : ' - '}</span>
                        </li>

                        <li><span className="left" style={{ width: 120 }}>Job Availability</span><span
                            className="middle">:</span>
                            <span
                                className="right">{resumeInfo.availability ? availabilityType(resumeInfo.availability) : '-'}</span>
                        </li>
                        <li><span className="left" style={{ width: 120 }}>Salary Expectation</span><span
                            className="middle">:</span> <span
                                className="right">{currencyFormat(resumeInfo.minimum_salary) || 'Negotiable'}</span></li>
                    </ul>
                </div>
            </li>


            {resumeInfo.career_objectives ? <li className="career-objective">
                <div className="icon">
                    <i className="fab fa-black-tie" aria-hidden="true" />
                </div>
                <div className="media-body">
                    <span className="tr-title">Career Objective</span>
                    <p>{resumeInfo.career_objectives}</p>
                </div>
            </li> : null}


            {experiences && experiences.length ? <li className="work-history">
                <div className="icon">
                    <i className="fa fa-briefcase" aria-hidden="true" />
                </div>
                <div className="media-body">
                    <span className="tr-title">Work History</span>
                    <ul className="tr-list">
                        {experiences.map((exp, i) => {
                            return (<li key={i}>
                                {exp.category && exp.category.name ?
                                    <span>{exp.category.name} @ {exp.company_name}</span>
                                    : <span>{exp.job_title} @ {exp.company_name}</span>}
                                &nbsp;<span
                                    className="present">From: {exp.from_date} - {exp.currently_working ? 'To: Present' : 'To:' + exp.to_date}</span>

                            </li>)
                        })}
                    </ul>
                </div>
            </li> : null}

            {academics && academics.length ? <li className="education-background">
                <div className="icon">
                    <i className="fas fa-university" />
                </div>
                <div className="media-body">
                    <span className="tr-title">Education Background</span>
                    <ul className="tr-list">
                        {academics.map((acd, i) => {
                            return (<li key={i}>
                                <span>{academicQualificationHead(acd.degree)} {acd.faculty ? ` in ${acd.faculty}` : ''} @ {acd.institute_name}</span>
                                <ul className="tr-list">

                                    <li>Result: {acd.gpa} {acd.gpa <= 4 ? 'GPA' : '%'}</li>

                                    <li> {(acd.degree === 'see' || acd.degree === 'unsee') ?
                                        <span>{acd.from_year}</span> : (acd.currently_studying ? 'From: ' + acd.from_year + ' To: Present' : ('From: ' + acd.from_year + ' To: ' + acd.to_year))}</li>

                                </ul>
                                <p>{acd.desc}</p>
                            </li>
                            );
                        })}
                    </ul>
                </div>
            </li> : null}

            {resumeInfo.special_qualifications && resumeInfo.special_qualifications.length > 0 && resumeInfo.special_qualifications[0][0].length ?
                <li className="qualification">
                    <div className="icon">
                        <i className="fas fa-running" />
                    </div>
                    <div className="media-body">
                        <span className="tr-title">Special Qualification:</span>
                        <ol>
                            {resumeInfo.special_qualifications.map(function (qua, i) {

                                return (qua[i].length ? <li key={i}>{qua[i]}
                                </li> : null
                                );
                            })}
                        </ol>
                    </div>
                </li> : null}

            {resumeInfo.languages && resumeInfo.languages.length && resumeInfo.languages[0].value > 0 ?
                <li className="language-proficiency">
                    <div className="icon">
                        <i className="fa fa-language" aria-hidden="true" />
                    </div>
                    <div className="media-body">
                        <span className="tr-title">Language Proficiency:</span>
                        <ul className="tr-list">
                            {resumeInfo.languages.map(function (item, i) {
                                return <li key={i}>
                                    <span>{languages[item.item]}</span>
                                    <ul className={'tr-list rating'}>
                                        {[...Array(item.value)].map(function (j, x) {
                                            return <li key={x + 100}><i className="fa fa-star" aria-hidden="true" /></li>
                                        })}

                                        {[...Array(5 - item.value)].map(function (j, x) {
                                            return <li key={x + 200}><i className="far fa-star" aria-hidden="true" />
                                            </li>
                                        })}
                                    </ul>
                                </li>
                            })}
                        </ul>
                    </div>
                </li> : null}

            {resumeInfo.declaration ? <li className="personal-deatils">
                <div className="icon">
                    <i className="fas fa-file-alt" />
                </div>
                <div className="media-body">
                    <span className="tr-title">Declaration</span>
                    <p>{resumeInfo.declaration}</p>
                </div>
            </li> : null}
        </ul>
    </div>);
};

ResumeDetail.propTypes = {
    academics: PropTypes.array,
    resumeInfo: PropTypes.object,
    personal: PropTypes.object,
    experiences: PropTypes.array,
};


export { ResumeDetail }