import React, {Component} from 'react';
import {Button, Drawer, Icon, Modal, Tooltip} from "antd";
import {concatCategories} from "../../utils/helpers";
import {ExpertyFieldsCategory} from "./ExpertyFieldsCategory";
import {Socials} from "../layouts/partials/Socials";
import {openNotificationWithIcon} from "../../utils/notification";
import {ResumeDetail} from "./ResumeDetail";
import {connect} from "react-redux";
import {downloadResume, jobGeneralState, shortListCandidate} from "../../store/actions";

const {confirm} = Modal;


class UserSideDrawerViewComponent extends Component {
    state = {
        selectedDeadLineId: '',
        copied: false
    };

    render() {
        const {selectedApplicant, expiry,hideActionButton} = this.props;
        const $this = this;
        return <Drawer
            width={'45%'}
            className={'applicant_profile_view'}
            title={<h3>Applicant
                Profile &nbsp;&nbsp;&nbsp; {selectedApplicant.applicant && selectedApplicant.applicant.save_type === 1 ?
                    <small style={{fontWeight: 'bold'}}><a href="#"><Icon
                        type={'check-circle'}/> Shortlisted</a></small> : null}</h3>}
            placement={'left'}
            closable={true}
            onClose={() => {
                    this.props.jobGeneralState({props: 'selectedApplicant', value: {}});
            }}
            visible={Object.keys(selectedApplicant).length > 0}>
            <div className="side_bar_profile">
                {selectedApplicant.personal ?
                    <div className="profile-intro">
                        <div className="image-box">
                            <img src={selectedApplicant.personal.image} alt=""/>
                            <input id={'copy-to-clip'} style={{height: '0px', border: 'none'}}
                                   defaultValue={location.origin + `/profile/${concatCategories(selectedApplicant.category, true)}-${selectedApplicant.personal.id}`}
                                   type="text"/>
                        </div>
                        <div className="intro-box">
                            <h3>{selectedApplicant.personal.full_name}
                                &nbsp;&nbsp;&nbsp;
                                <a target={'_blank'}
                                   href={`/profile/${concatCategories(selectedApplicant.category, true)}-${selectedApplicant.personal.id}`}><Icon
                                    type="select"/></a>
                            </h3>
                            <ExpertyFieldsCategory icon={false} categories={selectedApplicant.category}/>
                            <p>{selectedApplicant.personal.expertise_title}</p>

                            <Socials socials={selectedApplicant.personal.social} small={true}/>
                            <Tooltip
                                title={this.state.copied ? "Copied to clipboard" : "Copy profile link"}>
                                <Button onClick={() => {
                                    this.copyLink();
                                }} onMouseOut={() => {
                                    setTimeout(() => {
                                        this.setState({copied: false});
                                    }, 200);
                                }} type="primary" size={'small'}>
                                    Copy Profile URL
                                </Button>
                            </Tooltip>
                            &nbsp;
                            <Button type="default" size={'small'} onClick={() => {
                                this.props.downloadResume({userId: selectedApplicant.personal.id}).then((res) => {

                                }).catch(err => {
                                    openNotificationWithIcon('error', {
                                        title: 'Download Failed',
                                        description: err.response.data.msg
                                    });
                                });
                            }}>
                                Download CV
                            </Button>
                        </div>
                    </div> : null}

                {(selectedApplicant.academics && selectedApplicant.personal && selectedApplicant.resume_info && selectedApplicant.experiences) ?
                    <ResumeDetail academics={selectedApplicant.academics} personal={selectedApplicant.personal}
                                  resumeInfo={selectedApplicant.resume_info}
                                  experiences={selectedApplicant.experiences}/> : null}

                {selectedApplicant.personal && !hideActionButton ? <div className="shortlist-btn">
                    {selectedApplicant.applicant.save_type === 0 ? <Button onClick={() => {
                        this.props.shortListCandidate(selectedApplicant.personal.id, this.state.selectedDeadLineId || expiry);
                    }} block type={'primary'}>Shortlist</Button> : <Button onClick={() => {
                        confirm({
                            title: 'Decline shortlisted candidate?',
                            content: 'Click the Yes button if you want to remove this candidate from shortlisted group',
                            okText: 'Yes',
                            cancelText: 'No',
                            onOk() {
                                $this.props.shortListCandidate(selectedApplicant.personal.id, $this.state.selectedDeadLineId || expiry);
                            }
                        });
                    }} block type={'danger'}>Decline</Button>}
                </div> : null}
            </div>
        </Drawer>
    }

    copyLink() {
        const copyText = document.getElementById("copy-to-clip");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
        this.setState({copied: true});
    }
}


const mapStateToProps = (state) => {
    return {
        // feedback: state.feedback
    }
};

const UserSideDrawerView = connect(mapStateToProps, {
    downloadResume,
    jobGeneralState,
    shortListCandidate
})(UserSideDrawerViewComponent);

export {UserSideDrawerView};