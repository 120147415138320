import {Button, Input} from "antd";
import React from "react";
import PropTypes from 'prop-types';

const UpdatePassword = ({updatePassword, inputChange, rewriteOldPassword, cancelUpdate}) => {
    return (<div className={'container'}>
        <div className="row">
            <div className="col-sm-3">
                <label>Current Password<span className="pull-right">:</span> </label>
            </div>
            <div className="col-sm-8">
                <div className="form-group">
                    <Input type={'password'} value={updatePassword.old_password} placeholder=""
                           onChange={(e) => {
                               inputChange({
                                   props: 'old_password',
                                   value: e.target.value,
                                   type: 'updatePassword'
                               });
                           }}/>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-sm-3">
                <label>New Password<span className="pull-right">:</span> </label>
            </div>
            <div className="col-sm-8">
                <div className="form-group">
                <Input type={'password'} value={updatePassword.new_password} placeholder=""
                       onChange={(e) => {
                           inputChange({
                               props: 'new_password',
                               value: e.target.value,
                               type: 'updatePassword'
                           });
                       }}/></div>
            </div>
        </div>
        <div className="row">
            <div className="col-sm-3">
                <label>Confirm <span className="pull-right">:</span> </label>
            </div>
            <div className="col-sm-8">
                <div className="form-group">
                <Input type={'password'} value={updatePassword.new_password_confirmation}
                       placeholder=""
                       onChange={(e) => {
                           inputChange({
                               props: 'new_password_confirmation',
                               value: e.target.value,
                               type: 'updatePassword'
                           });
                           // resumeInputChange({props: 'full_name', value: ucWords(e.target.value)});
                       }}/></div>
            </div>
        </div>
        <hr/>

        <br/>


        <Button
            onClick={() => {
                cancelUpdate({
                    props: 'edit',
                    value: false,
                    type: 'password'
                });
            }}
            type="default">
            Cancel
        </Button>
        &nbsp;&nbsp;&nbsp;

        <Button loading={updatePassword.loader}
                disabled={!(updatePassword.new_password_confirmation.length && updatePassword.new_password.length && updatePassword.old_password.length)}
                onClick={() => {
                    rewriteOldPassword();
                }}
                type="primary">
            Update Password
        </Button>

    </div>);
};

UpdatePassword.propTypes = {
    updatePassword: PropTypes.object.isRequired,
    rewriteOldPassword: PropTypes.func.isRequired,
    inputChange: PropTypes.func.isRequired,
    cancelUpdate: PropTypes.func.isRequired
};


export {UpdatePassword};