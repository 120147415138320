import {Button, Icon, Input} from "antd";
import React from "react";
import {ucFirst} from "../../utils/helpers";
import {UpdatePassword} from "./UpdatePassword";

const PasswordSetting = ({user, auth, settingInputChange, authInputChange, rewriteOldPassword, updatePassword}) => {

    return user.data.pu_at ? <div className="setting-email-address">
        <div className="card-container">
            <div className="card-header">
                <h4>{user.settings.password.edit ? 'Update Password' : 'Password'}</h4>
            </div>
            {!user.settings.password.edit ? <div className="card-body">
                    <div className="icon-avatar">
                        <Icon type="lock"/>
                    </div>
                    <div className="body-content">
                        <p><strong>*******************</strong></p>
                        <small>Password last
                            updated {user.data.pu_at.str} | {ucFirst(user.data.pu_at.human)}</small>
                    </div>
                    <div className="body-action">
                        <Button size={'small'} loading={false} disabled={false}
                                onClick={() => {
                                    settingInputChange({
                                        props: 'edit',
                                        value: true,
                                        type: 'password'
                                    });
                                }}
                                type="default">
                            Edit
                        </Button>
                    </div>
                </div> :
                <div className="card-body"><UpdatePassword cancelUpdate={settingInputChange}
                                                           inputChange={authInputChange}
                                                           rewriteOldPassword={rewriteOldPassword}
                                                           updatePassword={updatePassword}/>
                </div>}


        </div>
    </div> : null

};

export {PasswordSetting};
