import React, {Component} from 'react';

class SideCrawl extends Component {
    constructor(props) {
        super(props);
        this.sideCrawl = React.createRef();
    }

    render() {
        let {left, show} = this.props;
        let top = 0;
        if (process.browser)
            top = document.querySelector('.small-device-navigation').clientHeight + 20;

        if (show && process.browser) {
            let browserWidth = document.body.clientWidth;
        }


        return <div ref={this.sideCrawl} style={{top}} className="category_mobile_view">
            <h1>CHECK CHECK</h1>
        </div>
    }

    componentDidMount() {
        this.sideCrawl.current.style.top = document.querySelector('.small-device-navigation').clientHeight + 20;
    }
}

export {SideCrawl};