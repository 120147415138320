import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

import { NotFound } from "./NotFound";
import { Icon } from "antd";
import { LogoGenerator } from '.';


const CompanyList = (props) => {
    const { data, ica, followCompany } = props;
    if (!data.length) return <NotFound />;
    const color = ['#F6941C', '#10B04A'];

    return data.map(function (item, i) {
        return (<div key={i} className="list_view">
            {item.logo !== 'https://rojal.s3.ap-south-1.amazonaws.com/rojal/logo/logo_placeholder.jpg' ?
                <div className="job_logo" style={{ flex: 1, height: '100%', width: '100%' }}>
                    <img
                        src={item.logo}
                        alt={item.name} />
                </div> : <div className="job_logo"><LogoGenerator name={item.name} /></div>}
            <div className="job_details">
                <h2>
                    <Link href={`/company/detail/[slug]`} as={`/company/detail/${item.slug}`}>
                        <a href={`/company/detail/${item.slug}`}>
                            {item.name}
                        </a>
                    </Link>
                </h2>
                <p>
                    {item.category ? <span><i className="fas fa-warehouse" /> {item.category.name}</span> : null}
                    , <span><i className="fas fa-map-marker-alt" /> {item.t_address}</span>
                </p>
                <p style={{ color: '#777', lineHeight: 1.5 }}>
                    <span><a href={`mailto:${item.email}`} style={{ color: '#777' }}>{item.email}</a></span>
                    , <span>{item.phone}</span><span>
                        {item.domain ?
                            <span>, <a
                                rel="noreferrer"
                                href={'http://www.' + item.domain.replace(new RegExp('\\.*www.', 'gi'), '').replace(/^http?.:\/\//, '')}
                                target={'_blank'}>
                                {item.domain ? item.domain.toLowerCase().replace(new RegExp('\\.*www.', 'gi'), '').replace(/^http?.:\/\//, '') : null}
                            </a></span> : null}
                    </span>
                </p>
            </div>
            <div className="job_action">
                <span className={'a_' + (item.job_count > 0 ? 'x' : '')}>
                    {item.job_count ? (item.job_count + (item.job_count > 1 ? ' openings' : ' opening')) : '0 opening'}
                </span>
                {!ica ? <a href="" onClick={(e) => {
                    e.preventDefault();
                    followCompany({ type: item.is_followed === true ? 0 : 1, company_id: item.id });
                }}
                    className={`btn btn-default ${item.is_followed === true ? 'following' : ''}`}>{item.is_followed ?
                        <Icon type="check-circle"
                            size={'tiny'} /> : null}{item.is_followed ? 'Following' : 'Follow'}</a> : null}
            </div>
        </div>);
    });
};

CompanyList.propTypes = {
    data: PropTypes.array.isRequired,
    myPost: PropTypes.bool,
};

export { CompanyList };