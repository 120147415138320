import React, {Component} from 'react';

class Socials extends Component {
    render() {
        const {socials, small} = this.props;
        if(!socials) return null;

        return (
            <ul className={"breadcrumb-social social-icon-bg  tr-list" + (this.props.small ? ' small' : '')}>
                {Object.keys(socials).map(function (key, i) {
                    if (key === 'facebook') {
                        return <li key={i}><a rel="noreferrer" href={'http://www.' + socials[key].replace(new RegExp('\\.*www.', 'gi'), '').replace(/^http?.:\/\//, '')} target={'_blank'}><i
                            className={"fab fa-facebook" + (small ? ' small' : '')}/></a>
                        </li>
                    } else if (key === 'twitter') {
                        return <li key={i}><a rel="noreferrer" href={'http://www.' + socials[key].replace(new RegExp('\\.*www.', 'gi'), '').replace(/^http?.:\/\//, '')} target={'_blank'}><i
                            className={"fab fa-twitter" + (small ? ' small' : '')}/></a>
                        </li>
                    } else if (key === 'youtube') {
                        return <li key={i}><a rel="noreferrer" href={'http://www.' + socials[key].replace(new RegExp('\\.*www.', 'gi'), '').replace(/^http?.:\/\//, '')} target={'_blank'}><i
                            className={"fab fa-youtube" + (small ? ' small' : '')}/></a>
                        </li>
                    } else if (key === 'linkedin') {
                        return <li key={i}><a rel="noreferrer" href={'http://www.' + socials[key].replace(new RegExp('\\.*www.', 'gi'), '').replace(/^http?.:\/\//, '')} target={'_blank'}><i
                            className={"fab fa-linkedin" + (small ? ' small' : '')}/></a>
                        </li>
                    }  else if (key === 'whatsapp') {
                        return <li key={i}><a rel="noreferrer" href={'https://web.whatsapp.com/send?phone=' + socials[key]} target={'_blank'}>
                            <i className={"fab fa-whatsapp" + (small ? ' small' : '')}
                               style={{backgroundColor: '#00e676'}}/></a>
                        </li>
                    } else if (key === 'instagram') {
                        return <li key={i}><a rel="noreferrer" href={'http://www.' + socials[key].replace(new RegExp('\\.*www.', 'gi'), '').replace(/^http?.:\/\//, '')} target={'_blank'}>
                            <i className={"fab fa-instagram" + (small ? ' small' : '')}
                               style={{backgroundColor: '#b93973'}}/></a>
                        </li>
                    }
                })}
            </ul>
        );
    }
}

export {Socials};