import React from "react";
import { Icon } from "antd";


const UserCVDemo = ({ cvDeclaration, cvLangSection, cvScrollingContainer, cvAboutSection, cvCareerObject, cvSkill, cvAcademic, cvExperience }) => {
    return <div onScroll={() => {
    }} ref={cvScrollingContainer} className="user_cv_des_modified" style={{ width: '750px' }} id="cv_user_paper">

        <div ref={cvAboutSection} className="users-intro-section">
            <div className="skew_container">
                <div className="content_info">
                    <div className="updated">
                        {/*<p>Resume Updated Jan 1st 2018</p>*/}
                    </div>
                    <div className="image_and_title">
                        <div className="title">
                            <br />
                            <h1>
                                Sample User Name</h1>
                            <p>Senior software engineer, fullstack developer.</p>
                        </div>
                        <div className="user_image">
                            <img crossOrigin={"anonymous"}
                                src="/images/profile_male.png"
                                alt="" />
                        </div>
                        <div className="clearfix" />
                    </div>
                </div>
            </div>
        </div>

        <div className="users_details">
            <div className="u_d_content">
                <div style={{ marginTop: 0 }}>
                    <table style={{ marginTop: -20, position: 'relative', top: 0 }} width="100%" border="0">
                        <tbody>
                            <tr>
                                <td width="25">
                                    <Icon type="mail" />
                                </td>
                                <td>
                                    <p className="title">Email</p>
                                    <p><a href="mailto:">example@email.com</a></p>
                                </td>
                                <td width="35">
                                    <Icon type="phone" />
                                </td>
                                <td>
                                    <p className="title">Phone</p>
                                    <p>98########</p>
                                </td>
                            </tr>
                            <tr>


                                <td width="25">
                                    <Icon type="calendar" />
                                </td>
                                <td>
                                    <p className="title">Date of Birth</p>
                                    <p>1992-12-25</p>
                                </td>

                                <td width="25">
                                    <Icon type="skin" />
                                </td>
                                <td>
                                    <p className="title">Gender</p>
                                    <p>Male</p>
                                </td>
                            </tr>
                            <tr>

                                <td width="25">
                                    <Icon type="money-collect" />
                                </td>
                                <td>
                                    <p className="title">Marital Status</p>
                                    <p>Single</p>
                                </td>
                                <td width="25">
                                    <Icon type="flag" />
                                </td>
                                <td>
                                    <p className="title">Nationality</p>
                                    <p>Nepalese</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="25">
                                    <Icon type="home" />
                                </td>
                                <td colSpan="4">
                                    <p className="title">
                                        Address Permanent/Temporary
                                    </p>
                                    <p>Satungal, Kathmandu, Nepal / Satungal, Kathmandu, Nepal</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="u_o_content">
                <table width="100%" border="0">
                    <tbody>

                        <tr>
                            <td width="25">
                                <Icon type="hourglass" />
                            </td>
                            <td>
                                <p className="title">Salary</p>
                                <p>100,000</p>
                            </td>
                        </tr>
                        <tr>
                            <td width="25">
                                <Icon type="solution" />
                            </td>
                            <td>
                                <p className="title">Area Of Expertise</p>
                                <p>Information and Communications Technology &gt; Software Development</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div ref={cvCareerObject} className="career_objective" style={{ paddingTop: 15 }}>
            <h2>My Career Objective</h2>
            <p>Highly motivated individual and a certified digital marketer with strong SEO and SEM skills, attention to
                detail, and a solid online marketing background looking to obtain a position of SEO Specialist with XYZ
                company.</p>
        </div>

        <div ref={cvAcademic} className="career_objective academic_qualifications"
            style={{ paddingTop: 10, marginTop: 50, position: 'relative' }}>
            <h2>Academic Qualifications</h2>

            <div className="academic-box">
                <div className="icon">
                    <i className="fas fa-book-reader" />
                </div>
                <div className="content">
                    <h1 style={{ marginBottom: 5 }}>Secondary (SEE/SLC), Score 60%</h1>
                    <p style={{ lineHeight: 1, marginBottom: 10 }}>
                        <i className="far fa-calendar-alt" /> From:
                        2014-12-22, To:
                        2018-10-11 &nbsp;&nbsp; <i className="fas fa-school" /> Prime English Boarding Secondary School.
                    </p>
                    <p style={{ lineHeight: 1.3, color: '#777', fontSize: 14 }}>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab aliquam consequatur dolor enim ex
                        explicabo, facilis fugiat fugit labore
                    </p>
                </div>
            </div>

            <div className="academic-box">
                <div className="icon">
                    <i className="fas fa-book-reader" />
                </div>
                <div className="content">
                    <h1 style={{ marginBottom: 5 }}>Higher Secondary (+2 ,Diploma, A Level ), Score 72%</h1>
                    <p style={{ lineHeight: 1, marginBottom: 10 }}><i className="far fa-calendar-alt" /> From:
                        2014-12-22, To:
                        2018-10-11 &nbsp;&nbsp;
                        <i className="fas fa-school" /> Prime English Boarding Secondary School. </p>
                    <p style={{ lineHeight: 1.3, color: '#777', fontSize: 14 }}>
                        Business Management, Behaviour, Management Theory, Research Methods Crime and Deviations.
                    </p>
                </div>
            </div>

            <div className="academic-box">
                <div className="icon">
                    <i className="fas fa-book-reader" />
                </div>
                <div className="content">
                    <h1 style={{ marginBottom: 5 }}>Vocational</h1>
                    <p style={{ lineHeight: 1, marginBottom: 10 }}><i className="far fa-calendar-alt" /> From:
                        2014-12-22, To:
                        2018-10-11 &nbsp;&nbsp;
                        <i className="fas fa-school" /> Sishu Sadan Madhyamik School. </p>
                    <p style={{ lineHeight: 1.3, color: '#777', fontSize: 14 }}>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab aliquam consequatur dolor enim ex
                        explicabo, facilis fugiat fugit labore
                    </p>
                </div>
            </div>
        </div>

        <div ref={cvExperience} className="career_objective academic_qualifications">
            <br />
            <h2>Experiences</h2>

            <div className="academic-box">
                <div className="icon">
                    <i className="fas fa-user-tie" />
                </div>
                <div className="content">
                    <h1 style={{ marginBottom: 5 }}>Marketing and Sales</h1>
                    <p style={{ lineHeight: 1, marginBottom: 10 }}><i className="far fa-calendar-alt" /> From:
                        2014-12-22, To:
                        2018-10-11 &nbsp;&nbsp; <i className="fas fa-school" /> XYZ Awesome Company. </p>
                    <p style={{ lineHeight: 1.3, color: '#777', fontSize: 14 }}>
                        Awarded Employee of the month for consistently achieving 15% above target sales.
                    </p>
                </div>
            </div>

            <div className="academic-box">
                <div className="icon">
                    <i className="fas fa-user-tie" />
                </div>
                <div className="content">
                    <h1 style={{ marginBottom: 5 }}>Senior Node Js Developer</h1>
                    <p style={{ lineHeight: 1, marginBottom: 10 }}><i className="far fa-calendar-alt" /> From:
                        2014-12-22, To:
                        2018-10-11 &nbsp;&nbsp; <i className="fas fa-school" /> Prime English Boarding Secondary School.
                    </p>
                    <p style={{ lineHeight: 1.3, color: '#777', fontSize: 14 }}>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab aliquam consequatur dolor enim
                        ex
                        explicabo, facilis fugiat fugit labore
                    </p>
                </div>
            </div>

        </div>


        <div ref={cvSkill} className="career_objective special-skills" style={{ position: 'relative', height: 300 }}>
            <br />
            <img style={{ position: 'absolute', right: -50, top: 50, opacity: 0.5 }}
                src="https://rojal.s3.ap-south-1.amazonaws.com/rojal/defaults/special_skill.png"
                width="250px;" alt="" />

            <h2>Special Skills</h2>

            <ul style={{ position: 'absolute' }}>
                <li><p>Can eat a extra large size pizza alone</p></li>
                <li>
                    <p>Switching tabs from Facebook to something work-related really fast every time my boss
                        approaches.</p>
                </li>

                <li><p>Can change jeans on the bus without anyone noticing</p></li>
                <li>
                    <p>Able to drive in the rain, wearing only one contact lens</p>
                </li>

            </ul>
        </div>


        <div ref={cvLangSection} className="career_objective" style={{ paddingTop: 10 }}>
            <h2>Languages</h2>
            <div className="languages_section">

                <div className="lang-box">
                    <h4>Nepali</h4>
                    <div className="rate">
                        <Icon type="star" theme="filled" />
                        <Icon type="star" theme="filled" />
                        <Icon type="star" theme="filled" />
                        <Icon type="star" theme="filled" />
                        <Icon type="star" theme="filled" />
                    </div>
                </div>

                <div className="lang-box">
                    <h4>Hindi</h4>
                    <div className="rate">
                        <Icon type="star" theme="filled" />
                        <Icon type="star" theme="filled" />
                        <Icon type="star" />
                        <Icon type="star" />
                        <Icon type="star" />
                    </div>
                </div>


                <div className="lang-box">
                    <h4>Newari</h4>
                    <div className="rate">

                        <Icon type="star" theme="filled" />
                        <Icon type="star" theme="filled" />
                        <Icon type="star" theme="filled" />
                        <Icon type="star" theme="filled" />
                        <Icon type="star" />
                    </div>
                </div>


                <div className="lang-box">
                    <h4>English</h4>
                    <div className="rate">

                        <Icon type="star" theme="filled" />
                        <Icon type="star" theme="filled" />
                        <Icon type="star" theme="filled" />
                        <Icon type="star" />
                        <Icon type="star" />

                    </div>
                </div>

            </div>
        </div>



        <div ref={cvDeclaration} className="career_objective">
            <h2>Declaration</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. A accusantium aspernatur debitis dolorem fugit
                harum hic nostrum nulla, odit qui reprehenderit rerum saepe sequi suscipit tempora totam voluptatem?
                Earum,
                ratione. tempora totam voluptatem?.</p>
        </div>

        <p className="cv_credit">CV Generate via <a href="https://rojgarisanjal.com">rojgarisanjal.com</a></p>
        <br />
        <br />

    </div >;
};


export { UserCVDemo }